import store from '@/store/index';
const { user } = store.getters;
export const tableConfig = {
    name: 'customerPersonResponserEdit',
    searchFields: [
        {
            name: '原责任人',
            value: 'owner',
            type: 'advanceMulti',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 2,
            advanceConfig: [
                { 'prop': 'userName', 'value': '用户名称' },
                { 'prop': 'userCode', 'value': '用户编码' }
            ],
            tableConfig: [
                { 'prop': 'userName', 'value': '用户名称', 'displayName': 'userName' },
                { 'prop': 'userCode', 'value': '用户编码' },
                { 'prop': 'userId', 'value': '用户销售易id' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/neoCrm/neoUser/query`,
            method: 'post',
            cbParams: ['userName#ownerName', 'userCode#ownerCode'],
            beforeRequest: (params) => {
                const inputKey = (params.userName ? params.userName : (params.userCode ? params.userCode : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.userName = inputKey;
                    params.userCode = null;
                } else {
                    params.userCode = inputKey;
                    params.userName = null;
                }
                params.applicant = user.userCode;
                params.status = '';
                return params;
            }
        },
        {
            name: '修改后责任人',
            value: 'respOwner',
            type: 'advanceMulti',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'userName', 'value': '用户名称' },
                { 'prop': 'userCode', 'value': '用户编码' }
            ],
            tableConfig: [
                { 'prop': 'userName', 'value': '用户名称', 'displayName': 'userName' },
                { 'prop': 'userCode', 'value': '用户编码' },
                { 'prop': 'userId', 'value': '用户销售易id' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/neoCrm/neoUser/query`,
            method: 'post',
            cbParams: ['userName#respOwnerName', 'userCode#respOwnerCode'],
            beforeRequest: (params) => {
                const inputKey = (params.userName ? params.userName : (params.userCode ? params.userCode : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.userName = inputKey;
                    params.userCode = null;
                } else {
                    params.userCode = inputKey;
                    params.userName = null;
                }
                params.applicant = user.userCode;
                params.status = '';
                return params;
            },
            record: true // 变更明细查询专属
        },
        {
            name: '责任人类型',
            value: 'respType',
            type: 'select',
            optionNum: 'NEOCRM_RESP_TYPE',
            isFixed: true,
            span: 2
        },
        {
            name: '客户名称',
            value: 'customer',
            type: 'advanceMulti',
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            tableConfig: [
                { prop: 'customerName', value: '客户名称', 'displayName': 'customerName' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/customer/info/page',
            cbParams: [ 'customerCode', 'customerName' ],
            beforeRequest: (params) => {
                params.userCode = user.userCode;
                return params;
            },
            isFixed: true,
            span: 2
        },
        {
            name: '行业',
            value: 'industryType',
            type: 'advanceMulti',
            advanceConfig: [
                { prop: 'industryTypeName', value: '行业名称' },
                { prop: 'industryTypeCode', value: '行业编码' }
            ],
            tableConfig: [
                { prop: 'industryTypeName', value: '行业名称', displayName: 'industryTypeName' },
                { prop: 'industryTypeCode', value: '行业编码' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/industry/info/page',
            cbParams: [ 'industryTypeCode', 'industryTypeName' ],
            beforeRequest: (params) => {
                params.userCode = user.userCode;
                return params;
            },
            isFixed: true,
            span: 3
        },
        {
            name: '分公司',
            value: 'companyName',
            type: 'advance',
            isFixed: true,
            span: 2,
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称', 'displayName': 'companyNameCn' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: [ 'companyCode', 'companyNameCn#companyName' ]
        },
        {
            name: '经营中心',
            value: 'businessCenterName',
            type: 'advance',
            isFixed: true,
            span: 2,
            advanceConfig: [
                { prop: 'businessCenterName', value: '经营中心名称' },
                { prop: 'businessCenterCode', value: '经营中心编码' }
            ],
            tableConfig: [
                { prop: 'businessCenterName', value: '经营中心名称', displayName: 'businessCenterName' },
                { prop: 'businessCenterCode', value: '经营中心编码' }
            ],
            prefix: '/api-lcs',
            advanceUrl: '/bid/queryCompanyByCodeAndName',
            cbParams: ['businessCenterCode', 'businessCenterName'],
            advanceCascade: 'companyCode#companyCode' // 关联入参只针对单选有效
        },
        {
            name: '服务平台',
            value: 'siteName',
            type: 'advance',
            isFixed: true,
            span: 2,
            advanceConfig: [
                { prop: 'siteNameCn', value: '服务平台名称' },
                { prop: 'siteCode', value: '服务平台编码' }
            ],
            tableConfig: [
                { prop: 'siteCode', value: '服务平台编码' },
                { prop: 'siteNameCn', value: '服务平台名称', displayName: 'esusUserNameCn' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popSite`,
            cbParams: [ 'siteCode', 'siteNameCn#siteName' ],
            advanceCascade: 'companyCode#companyCode' // 关联入参只针对单选有效
        }
    ],
    selection: true,
    columns: [
        {
            label: '责任人类型',
            prop: 'respType',
            type: 'select',
            optionsKey: 'NEOCRM_RESP_TYPE',
            disabled: true,
            width: 140
        },
        {
            label: '客户名称',
            prop: 'customerName',
            sort: true,
            width: 200
        },
        {
            label: '客户编码',
            prop: 'customerCode',
            sort: true,
            width: 140
        },
        {
            label: '责任人划分方式',
            prop: 'respDivide',
            type: 'select',
            optionsKey: 'NEOCRM_RESP_DIVIDE',
            placeholder: '责任人划分方式',
            disabled: true,
            width: 140
        },
        {
            label: '行业',
            prop: 'industryTypeName'
        },
        {
            label: '分公司',
            prop: 'companyName'
        },
        {
            label: '经营中心',
            prop: 'businessCenterName'
        },
        {
            label: '服务平台',
            prop: 'siteName'
        },
        {
            label: '原责任人',
            prop: 'ownerName'
        },
        {
            label: '修改后责任人',
            prop: 'respOwnerName',
            value: 'respOwnerName',
            name: '添加责任人',
            type: 'advance',
            mustFill: true,
            advanceConfig: [
                { 'prop': 'userName', 'value': '用户名称' },
                { 'prop': 'userCode', 'value': '用户编码' }
            ],
            tableConfig: [
                { 'prop': 'userName', 'value': '用户名称', 'displayName': 'userName' },
                { 'prop': 'userCode', 'value': '用户编码' },
                { 'prop': 'userId', 'value': '用户销售易id' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/neoCrm/neoUser/query`,
            method: 'post',
            cbParams: ['userName#respOwnerName', 'userCode#respOwnerCode', 'userId#respOwnerId'],
            beforeRequest: (params) => {
                const inputKey = (params.userName ? params.userName : (params.userCode ? params.userCode : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.userName = inputKey;
                    params.userCode = null;
                } else {
                    params.userCode = inputKey;
                    params.userName = null;
                }
                params.applicant = user.userCode;
                params.status = 1;
                return params;
            },
            width: 180,
            record: true // 变更明细查询专属
        }
    ]
};
