import request from '@/utils/http';
import utils from '@/utils/common';
const apiCrmHost = 'api-lcrm';

// 列表查询-责任人信息查询
export const neoCrmRespChangeQuery = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/respChange/query',
        method: 'post',
        data
    });
};
// 列表查询-责任人变更记录查询
export const neoCrmRespDetailQuery = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/respDetail/query',
        method: 'post',
        data
    });
};
// 变更明细保存
export const neoRespChangeSave = (data) => {
    return request({
        url: apiCrmHost + '/neo/respChange/save',
        method: 'post',
        data
    });
};
// 变更明细删除
export const neoRespChangeDelete = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/respChange/delete',
        method: 'post',
        data
    });
};
