<!-- eslint-disable max-lines -->
<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="header-box">
            <h4>责任人变更流程</h4>
            <div class="header-part">
                <div class="apply-info">
                    <div>变更单号：{{ detailDataLocal.customerChange }}</div>
                    <div>申请人：{{ detailDataLocal.applicantName }}</div>
                    <div>申请人部门：{{ detailDataLocal.departName }}</div>
                </div>
            </div>
        </div>
        <div class="list-box" v-show="!readonly">
            <el-menu
                :default-active="isFull"
                class="el-menu-demo"
                mode="horizontal">
                <el-menu-item index="N">责任人信息查询</el-menu-item>
            </el-menu>
            <search-box
                ref="searchBoxQuery"
                :search-key="config.name"
                :fields="newConfig.searchFields.querySearchFields"
                :showResetBtn="true"
                :searchBtnBoxSpan="8"
                @search-change="accountList.searchList">
                <template slot="btn-append">
                    <lots-button type="primary" @click="checkConfirm('query')">选取</lots-button>
                    <lots-button type="primary" @click="checkAll('query')">选取全部</lots-button>
                </template>
            </search-box>
            <table-box
                ref="tableBox"
                v-loading="accountList.tableLoading"
                :selection="config.selection"
                :columns="newConfig.columns.queryColumns"
                :height="300"
                :rows="accountList.totalData"
                :allowRowClick="true"
                @selection-change="accountList.handleSelectedRow"
                @select-all="(selection) => handleTableAll('query', selection)">
            </table-box>
            <lots-pagination
                @size-change="accountList.sizeChange"
                :current-page.sync="accountList.pageNo"
                @current-change="accountList.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="accountList.pageTotal" >
            </lots-pagination>
        </div>
        <div class="list-box second-box" :class="{'margin-box': !readonly}">
            <el-menu
                :default-active="isFull"
                class="el-menu-demo"
                mode="horizontal">
                <el-menu-item index="N">责任人变更记录</el-menu-item>
            </el-menu>
            <search-box
                ref="searchBoxDetail"
                :search-key="config.name"
                :fields="newConfig.searchFields.detailSearchFields"
                :showResetBtn="true"
                :searchBtnBoxSpan="8"
                @search-change="detailList.searchList">
                <template slot="btn-append" v-if="!readonly">
                    <lots-button type="primary" @click="checkConfirm('detail')">批量变更</lots-button>
                    <lots-button type="primary" @click="checkAll('detail')">全量变更</lots-button>
                    <lots-button type="primary" @click="handleDelect('delete')" :loading="deleteLoading">删除</lots-button>
                    <lots-button type="primary" @click="handleDelect('deleteAll')" :loading="deleteAllLoading">删除全部</lots-button>
                </template>
            </search-box>
            <edit-table-box
                ref="editTableBox"
                :key="selectKey"
                v-loading="detailList.tableLoading"
                :control="false"
                :selection="config.selection"
                :columns="newConfig.columns.detailColumns"
                :height="300"
                :tableData="detailList.totalData"
                :allowRowClick="!readonly"
                @selection-change="detailList.handleSelectedRow"
                @element-change="handleChangeRow"
                @select-all="(selection) => handleTableAll('detail', selection)">
                    <template #col-header="{ item }">
                        <span v-if="item.prop === 'respOwnerName'">
                            {{ item.label }}
                            <div class="edit-filter-box">
                                <el-select v-model="respOwnerFilter" @change="handleFilter" :clearable="true" placeholder="过滤">
                                    <el-option label="空值" :value="true"> </el-option>
                                </el-select>
                            </div>
                        </span>
                        <span v-else>{{ item.label }}</span>
                    </template>
            </edit-table-box>
            <lots-pagination
                @size-change="detailList.sizeChange"
                :current-page.sync="detailList.pageNo"
                @current-change="detailList.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="detailList.pageTotal" >
            </lots-pagination>
        </div>
        <div class="list-box third-box">
            <el-form
                ref="outForm"
                :model="detailDataLocal"
                label-width="100px"
                label-position="left"
                :rules="rules">
                <el-form-item label="流程标题" prop="processTitle">
                    <el-input v-model="detailDataLocal.processTitle" @blur="handleBlur('processTitle')" :disabled="readonly" clearable></el-input>
                </el-form-item>
                <el-form-item label="变更说明" prop="changeDescription">
                    <el-input type="textarea" v-model="detailDataLocal.changeDescription" rows="4" @blur="handleBlur('changeDescription')" :disabled="readonly"></el-input>
                </el-form-item>
                <el-form-item label="附件" prop="respChangeFile">
                    <lots-upload-button :text="'上传附件'" :bucket="'annto-lcrm'"
                        @callback="upLoadData" :disabled="readonly">
                    </lots-upload-button>
                    <div class="file-infos">
                        <template v-if="detailDataLocal.respChangeFile && detailDataLocal.respChangeFile.length">
                            <div
                                class="file-list"
                                v-for="(item, index) in detailDataLocal.respChangeFile"
                                :key="index + item.fileName">
                                <div
                                    class="file-name upload"
                                    :title="item.fileName">
                                    {{`${index+1}. `}}
                                    <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
                                </div>
                                <div class="delete-btn">
                                    <el-button
                                        type="text"
                                        plain>
                                        <a :href="item.fileUrl" target="_blank">下载</a>
                                    </el-button>
                                    <el-button
                                        type="text"
                                        plain
                                        @click="handleDeleteFile(index)"
                                        :disabled="readonly">删除</el-button>
                                </div>
                            </div>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div
            v-if="detailDataLocal.mipFlowId"
            class="iflow-box">
            <div class="header-operation">
                <lots-button
                    size="mini"
                    type="primary"
                    @click="submitMip"
                    :loading="btnloading || submitLoading">
                    mip提交
                </lots-button>
            </div>
            <iframe
                class="ifr"
                :src="mipUrl"
                v-if="!btnloading" />
        </div>
        <check-dialog
            v-if="checkConfirmVisible"
            :dialogType="dialogType"
            :dialogTitle="dialogTitle"
            :confirmLoading="confirmLoading"
            :rows="selectedRows"
            :queryCheckAll="queryCheckAll"
            :detailCheckAll="detailCheckAll"
            @confirm="checkConfirmCb"
            @handleClose="checkConfirmVisible = false">
        </check-dialog>
    </paper>
</template>

<script>
import { ref, reactive, onActivated, computed, getCurrentInstance, onMounted, watch } from '@vue/composition-api';
import paper from '@/components/core/Paper.vue';
import searchBox from '@/components/lots/searchBox';
import lotsButton from '@/components/lots/lotsButton';
import { tableConfig } from './config.js';
import tableBox from '@/components/lots/tableBox/Index';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsUploadButton from '@/components/lots/oss/UploadBtn';
import { neoCrmRespChangeQuery, neoCrmRespDetailQuery, neoRespChangeSave, neoRespChangeDelete } from '@mdm/api/customer/customerPersonResponserEdit.js';
import { approveProcess } from '@/modules/mdm/api/contractManage/detailMainApi.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { Message, MessageBox } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
import _ from 'lodash';
import checkDialog from './checkDialog/Index';
import configurl from '@/config/user.env';

const { MAGIC_NUMBER } = utils;
export default {
    name: 'customerPersonResponserEdit',
    components: { paper, searchBox, lotsButton, tableBox, editTableBox, lotsPagination, checkDialog, lotsUploadButton },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => { }
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const EDIT_TYPE = {
            EDIT: 2,
            CANCEL: -3
        };
        const { orgName, userName, userCode } = store.getters.user;
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(tableConfig);
        const btnloading = ref(false);
        const detailLoading = ref(false);
        const isFull = ref('N');
        const detailDataLocal = reactive({
            customerChange: '',
            applicant: '',
            applicantCode: '',
            applicantName: '',
            departName: '',
            processTitle: '',
            changeDescription: '',
            respChangeFile: [],
            mipFlowId: '',
            mipStatus: '',
            respType: '' // 责任人类型（1：行业 2：分公司）
        });
        const respOwnerFilter = ref('');
        const selectKey = ref(false);
        const handleFilter = (data) => {
            selectKey.value = !selectKey.value;
            detailList.getList();
        };
        const submitLoading = ref(false);
        const readonly = computed(() => {
            return [2, 3, 4, 7].includes(+detailDataLocal.mipStatus) || submitLoading.value || btnloading.value;
        });
        const rules = reactive({
            processTitle: [
                { required: true, message: '请填写流程标题', trigger: 'blur' }
            ],
            changeDescription: [
                { required: true, message: '请填写变更说明', trigger: 'blur' }
            ],
            respChangeFile: [
                {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (rule.required && !value.length) {
                            callback(new Error('附件不能为空'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }
            ]
        });
        const newConfig = computed(() => {
            const newSearchFields = _.cloneDeep(config.searchFields);
            const querySearchFields = [];
            newSearchFields.forEach(item => {
                if (item.value === 'industryType') {
                    item.disabled = detailDataLocal.respType === '1';
                } else if (item.value === 'companyName') {
                    item.disabled = detailDataLocal.respType === '2';
                } else if (item.value === 'respType') {
                    item.disabled = true;
                }
                // 必须发放在最后
                !item.record && querySearchFields.push(item);
            });
            const queryColumns = [];
            config.columns.forEach((item) => {
                !item.record && queryColumns.push(item);
            });
            return {
                ...config,
                searchFields: {
                    querySearchFields,
                    detailSearchFields: config.searchFields
                },
                columns: {
                    queryColumns,
                    detailColumns: config.columns
                }
            };
        });
        class AccountListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition, {}, 'query');
            };
            async afterGetList(res) {
                if (res && +res.code === 0 && res.data) {
                    accountList.pageTotal.value = res.data.totalCount;
                    res.data.list && (accountList.totalData.value = res.data.list);
                    initData(res.data);
                    if (detailDataLocal.mipFlowId) {
                        await getiflowInfo(detailDataLocal.customerChange);
                        handleCreate();
                    }
                }
            };
        };
        class DetailListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition, {}, 'detail');
            };
            afterGetList(res) {
                if (+res.code === 0 && res.data) {
                    detailList.pageTotal.value = res.data.totalCount;
                    if (res.data.list) {
                        detailList.totalData.value = res.data.list.map((v, index) => {
                            v.edit = readonly.value ? EDIT_TYPE.CANCEL : EDIT_TYPE.EDIT;
                            return v;
                        });
                    }
                }
            };
        };
        const accountList = new AccountListClass({
            Api: {
                listApi: neoCrmRespChangeQuery // 责任人信息查询接口
            }
        });
        const detailList = new DetailListClass({
            Api: {
                listApi: neoCrmRespDetailQuery // 责任人变更明细查询接口
            }
        });
        // 数据初始化
        const initData = (data = {}) => {
            const { customerChange, applicantCode, applicantName, departName, processTitle,
                changeDescription, respChangeFile, mipFlowId, respType, industryType, companyCode, companyName } = data;
            detailDataLocal.customerChange = customerChange;
            detailDataLocal.applicantCode = applicantCode;
            detailDataLocal.applicantName = applicantName;
            detailDataLocal.departName = departName;
            detailDataLocal.processTitle = processTitle;
            detailDataLocal.changeDescription = changeDescription;
            detailDataLocal.respChangeFile = respChangeFile || [];
            newMip.value = detailDataLocal.mipFlowId !== mipFlowId;
            detailDataLocal.mipFlowId = mipFlowId || undefined;
            const queryParams = { respType, industryType, companyCode, companyName };
            return queryParams;
        };
        watch(() => readonly.value, (newVal) => {
            detailList.totalData.value.forEach(item => {
                item.edit = newVal ? EDIT_TYPE.CANCEL : EDIT_TYPE.EDIT;
            });
        });
        const refleshPage = () => {
            accountList.getList();
            detailList.getList();
        };
        const getiflowInfo = async (customerChange = ctx.$route.query.customerChange) => {
            const iflowRes = await contractWorkflowList({ contractCode: customerChange });
            if (+iflowRes.code === 0 && iflowRes.data && iflowRes.data.list && iflowRes.data.list.length) {
                const { mipId, mipStatus } = iflowRes.data.list[0];
                detailDataLocal.mipFlowId = mipId;
                detailDataLocal.mipStatus = mipStatus;
            }
        };
        const handleChangeRow = _.debounce(({ prop, val, type, row, index }) => {
            if (prop === 'respOwnerName') {
                if (val?.respOwnerId) {
                    const params = {
                        ...detailDataLocal,
                        saveType: 'update',
                        list: rowsFormatFn([row]),
                        applicant: userCode,
                        respOwnerId: val?.respOwnerId || undefined
                    };
                    seveDetails(params);
                } else {
                    Message.warning('修改后责任人不能为空');
                    detailList.getList();
                }
            }
        }, MAGIC_NUMBER.FIVE_HUNDRED);
        const searchDataFormat = (condition = {}, params = {}, name) => {
            const { customerChange } = ctx.$route.query;
            if (condition?.industryType?.length) {
                condition.industryTypeCodes = '';
                condition.industryTypeNames = '';
                condition.industryType.forEach((item, index) => {
                    condition.industryTypeCodes += item.industryTypeCode + (index < condition.industryType.length - 1 ? ',' : '');
                    condition.industryTypeNames += item.industryTypeName + (index < condition.industryType.length - 1 ? ',' : '');
                });
            }
            condition.industryType = undefined;
            condition.customerChange = customerChange || detailDataLocal.customerChange;
            condition.applicant = userCode;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            if (name === 'detail') {
                if (respOwnerFilter.value) {
                    condition.respOwnerCodes = undefined;
                    condition.respOwnerNames = undefined;
                    condition.respOwnerIds = ''; // 变更明细筛选修改责任人为空值
                }
            }
            return { ...condition, ...params };
        };
        const dialogType = ref('');
        const checkConfirmVisible = ref(false);
        const selectedRows = ref([]);
        const checkConfirm = (name) => {
            dialogType.value = name;
            if (name === 'query') {
                if (!accountList.selectedRows.value.length) {
                    return Message.warning('请选中至少一条数据');
                }
                queryCheckAll.value = false;
                dialogTitle.value = '选取变更记录';
                selectedRows.value = accountList.selectedRows.value;
            } else if (name === 'detail') {
                if (!detailList.selectedRows.value.length) {
                    return Message.warning('请选中至少一条数据');
                }
                detailCheckAll.value = false;
                dialogTitle.value = '批量变更记录';
                selectedRows.value = detailList.selectedRows.value;
            }
            checkConfirmVisible.value = true;
        };
        const confirmLoading = ref(false);
        const checkConfirmCb = (data) => {
            let params = {
                ...detailDataLocal,
                applicant: userCode, // 操作人
                respOwnerId: data?.respOwnerId || undefined
            };
            if (dialogType.value === 'query') {
                if (queryCheckAll.value) { // 全选
                    const newData = _.cloneDeep(accountList.searchModel.value); // 避免修改了搜索参数
                    params = {
                        ...params,
                        ...newData,
                        saveType: 'insertAll'
                    };
                } else { // 非全选
                    params.saveType = 'insert';
                    params.list = rowsFormatFn(accountList.selectedRows.value);
                }
            } else if (dialogType.value === 'detail') {
                if (detailCheckAll.value) { // 全选
                    const newData = _.cloneDeep(detailList.searchModel.value);
                    params = {
                        ...params,
                        ...newData,
                        saveType: 'updateAll'
                    };
                } else { // 非全选
                    params.saveType = 'update';
                    params.list = rowsFormatFn(detailList.selectedRows.value);
                }
            }
            seveDetails(params);
        };
        // 编辑表格保存数据格式化
        const rowsFormatFn = (list) => {
            return list.map(item => {
                return _.omit(item, ['backup', 'edit', 'allowEdit', 'serialNum']);
            });
        };
        // 变更明细表保存
        const seveDetails = async (params) => {
            confirmLoading.value = true;
            await getiflowInfo();
            confirmLoading.value = false;
            if (!readonly.value) {
                const newParams = _.omit(params, ['applicantCode', 'applicantName', 'mipStatus', 'departName']);
                confirmLoading.value = true;
                const res = await neoRespChangeSave(newParams);
                confirmLoading.value = false;
                if (res && +res.code === 0) {
                    Message.success('保存成功');
                    checkConfirmVisible.value = false;
                    queryCheckAll.value = false;
                    detailCheckAll.value = false;
                    refleshPage();
                }
            } else {
                return Message.warning('页面状态与实际流程状态不符，请刷新页面再操作');
            }
        };
        const queryCheckAll = ref(false);
        const detailCheckAll = ref(false);
        const dialogTitle = ref('选择责任人');
        const checkAll = async(name) => {
            dialogType.value = name;
            if (name === 'query') {
                await setupContext.refs.searchBoxQuery.submitSearch(); // 解决输入参数不生效问题
                queryCheckAll.value = true;
                dialogTitle.value = '选取变更记录';
                selectedRows.value = accountList.selectedRows.value;
            } else if (name === 'detail') {
                await setupContext.refs.searchBoxDetail.submitSearch();
                detailCheckAll.value = true;
                dialogTitle.value = '批量变更记录';
                selectedRows.value = detailList.selectedRows.value;
            }
            checkConfirmVisible.value = true;
        };
        const handleTableAll = (name, selection) => {
            if (!selection?.length) { // 取消全选
                if (name === 'query') {
                    queryCheckAll.value = false;
                } else if (name === 'detail') {
                    detailCheckAll.value = false;
                }
            }
        };
        // 基本信息保存
        const saveBasic = () => {
            const params = {
                ...detailDataLocal,
                saveType: '',
                applicant: userCode
            };
            seveDetails(params);
        };
        const upLoadData = (data) => {
            if (data && +data.code === 0) {
                detailDataLocal.respChangeFile.push({ fileName: data.data.name, fileUrl: data.data.downUrl });
                setupContext.refs.outForm.validateField('respChangeFile', (err) => !err && saveBasic());
            }
        };
        const handleDeleteFile = (index) => {
            detailDataLocal.respChangeFile.splice(index, 1);
            setupContext.refs.outForm.validateField('respChangeFile', (err) => !err && saveBasic());
        };
        const handleBlur = (prop) => {
            setupContext.refs.outForm.validateField(prop, (err) => !err && saveBasic());
        };
        // iflow相关：
        const newMip = ref(false);
        const mipUrl = ref('');
        const handleCreate = () => {
            mipUrl.value = `${configurl.iflowUrl}?fdId=${detailDataLocal.mipFlowId}&fdTemplateKey=CRM_resp_change&userCode=${userCode}&userName=${userName}&timestamp=${new Date().getTime()}`;
            createMetaNode();
            window.addEventListener('message', messageFunc, false);
            if (newMip.value) { // 新产生的审批流，自动滚动到审批位置
                const timer = setTimeout(() => {
                    const element = document.getElementsByClassName('mip-iframe')[0];
                    element.scrollTo(0, MAGIC_NUMBER.ONE_THOUSAND); // 页面滚动到MIP审批位置
                    newMip.value = false;
                    clearTimeout(timer);
                }, MAGIC_NUMBER.FOUR_HUNDRED);
            }
        };
        const createMetaNode = () => {
            const mipmeta = document.getElementById('mipmeta');
            if (mipmeta) {
                return false;
            }
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = 'upgrade-insecure-requests';
            meta.id = 'mipmeta';
            document.getElementsByTagName('head')[0].appendChild(meta);
        };
        const messageFunc = (event) => {
            if (event.data && event.data.data && event.data.success) {
                const { customerChange, processTitle } = detailDataLocal;
                const { origin, pathname } = window.location;
                const params = event.data.data || {};
                const { tenantCode } = store.getters.currentTenant;
                params.tenantCode = tenantCode;
                // params.currentApplicationCode = this.applicationCode;
                params.currentApplicationCode = 'APP201904230047';
                if (params.formParam) {
                    params.formParam.formInstanceId = customerChange;
                    const { processParam } = params;
                    let isToOtherPersons = '';
                    if (processParam.operationType === 'circulate' && processParam.toOtherPersons) {
                        isToOtherPersons = processParam.toOtherPersons; // 被传阅的人
                    }
                    // eslint-disable-next-line max-len
                    params.formParam.fdUrl = `${origin}${pathname}#/customerPersonResponserEdit?customerChange=${customerChange}${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                    params.formParam.subject = processTitle;
                    const { subjectForMultiLanguages } = params.formParam;
                    subjectForMultiLanguages?.length && subjectForMultiLanguages.forEach(item => {
                        item.subject = processTitle;
                    });
                }
                btnloading.value = true;
                approveProcess(params).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('提交成功');
                        btnloading.value = true;
                        const timer = setTimeout(() => {
                            refleshPage();
                            btnloading.value = false;
                            clearTimeout(timer);
                        }, MAGIC_NUMBER.THREE_THOUSAND);
                    } else {
                        Message.error(res.msg);
                    }
                }).finally(() => {
                    btnloading.value = false;
                });
            }
        };
        const submitMip = async () => {
            // eslint-disable-next-line complexity
            setupContext.refs.outForm.validate(async(flag) => {
                if (flag) {
                    const { customerChange, mipFlowId } = detailDataLocal;
                    submitLoading.value = true;
                    // mip提交前校验页面流程信息与流程实际信息是否符合
                    const iflowRes = await contractWorkflowList({ contractCode: customerChange || ctx.$route.query.customerChange });
                    submitLoading.value = false;
                    if (iflowRes?.code === '0') {
                        if (iflowRes.data?.list?.length && iflowRes.data.list[0].mipId === mipFlowId) {
                            const params = {
                                customerChange: customerChange || ctx.$route.query.customerChange,
                                applicant: userCode,
                                pageNo: 1,
                                pageSize: 10000
                            };
                            submitLoading.value = true;
                            const resCheckLength = await neoCrmRespDetailQuery(params);
                            submitLoading.value = false;
                            // eslint-disable-next-line max-depth
                            if (resCheckLength?.code === '0' && !resCheckLength.data?.list?.length) {
                                return Message.warning('变更记录数据为空，请添加变更明细再操作');
                            }
                            submitLoading.value = true;
                            const resCheckFill = await neoCrmRespDetailQuery({ ...params, respOwnerIds: '' });
                            submitLoading.value = false;
                            // eslint-disable-next-line max-depth
                            if (resCheckFill?.code === '0' && resCheckFill.data?.list?.length) {
                                return Message.warning('存在修改后责任人未填的数据，请排查补充完整或删除再操作');
                            }
                            const content = window;
                            content.frames && content.frames.length && content.frames[0]
                                && content.frames[0].postMessage('mbpm_getApprovalData', mipUrl.value);
                            content.frames && content.frames.length && content.frames[1]
                                && content.frames[1].postMessage('mbpm_getApprovalData', mipUrl.value); // 解决接入了智能客服后，content.frames[0]被占用了的问题
                        } else {
                            Message.warning('页面审批流程信息已过期，请刷新页面再试');
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    Message.warning('请填写必填信息');
                }
            });
        };
        const deleteLoading = ref(false);
        const deleteAllLoading = ref(false);
        // eslint-disable-next-line max-lines-per-function
        const handleDelect = async (type) => {
            if (type === 'delete') {
                deleteLoading.value = true;
            } else {
                deleteAllLoading.value = true;
            }
            await getiflowInfo();
            deleteLoading.value = false;
            deleteAllLoading.value = false;
            if (!readonly.value) {
                let params = {};
                if (type === 'delete') {
                    if (!detailList.selectedRows.value.length) {
                        return Message.warning('请选中至少一条数据');
                    }
                    params = {
                        customerChange: detailDataLocal.customerChange,
                        deleteType: type,
                        list: rowsFormatFn(detailList.selectedRows.value)
                    };
                } else {
                    await setupContext.refs.searchBoxDetail.submitSearch(); // 解决输入参数不生效问题
                    const { value } = detailList.searchModel;
                    const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
                    params = {
                        ...detailDataLocal,
                        deleteType: type,
                        ...newData
                    };
                }
                MessageBox.confirm(`确认要删除${type === 'delete' ? '选中' : '所有'}数据吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    if (type === 'delete') {
                        deleteLoading.value = true;
                    } else {
                        deleteAllLoading.value = true;
                    }
                    const newParams = _.omit(params, ['applicantCode', 'applicantName', 'mipStatus', 'departName', 'changeDescription', 'respChangeFile']);
                    newParams.applicant = userCode;
                    const res = await neoRespChangeDelete(newParams);
                    deleteLoading.value = false;
                    deleteAllLoading.value = false;
                    if (res && +res.code === 0) {
                        Message.success('删除成功');
                        refleshPage();
                    }
                });
            } else {
                return Message.warning('页面状态与实际流程状态不符，请刷新页面再操作');
            }
        };
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout(); // 解决表格错位
        });
        const dictData = ref({});
        onMounted(() => {
            const { customerChange } = ctx.$route.query;
            detailDataLocal.customerChange = customerChange || '';
            detailDataLocal.applicant = userCode;
            detailDataLocal.applicantName = userName;
            const orgArr = orgName.split('_');
            detailDataLocal.departName = orgArr[orgArr.length - 2];
            const params = {
                customerChange,
                applicant: userCode,
                pageNo: 1,
                pageSize: 10
            };
            neoCrmRespChangeQuery(params).then(res => {
                if (res?.code === '0' && res.data) {
                    detailDataLocal.respType = res.data.respType;
                    // 先获取基础信息之后
                    const queryParams = initData(res.data);
                    setupContext.refs.searchBoxQuery.setQueryParams(queryParams);
                    // 再调查询数据接口
                    const timer = setTimeout(() => {
                        // refleshPage();
                        setupContext.refs.searchBoxQuery.submitSearch();
                        setupContext.refs.searchBoxDetail.submitSearch();
                        clearTimeout(timer);
                    }, 100);
                }
            });
        });
        return {
            config,
            newConfig,
            btnloading,
            readonly,
            detailDataLocal,
            rules,
            detailLoading,
            refleshPage,
            isFull,
            accountList,
            detailList,
            handleChangeRow,
            dictData,
            checkConfirmVisible,
            checkConfirm,
            checkAll,
            checkConfirmCb,
            handleTableAll,
            queryCheckAll,
            upLoadData,
            handleDeleteFile,
            handleBlur,
            handleDelect,
            confirmLoading,
            detailCheckAll,
            dialogType,
            dialogTitle,
            selectedRows,
            mipUrl,
            handleCreate,
            submitMip,
            respOwnerFilter,
            handleFilter,
            selectKey,
            deleteLoading,
            deleteAllLoading,
            submitLoading
        };
    }
};
</script>
<style lang="less">
.mip-iframe {
    overflow: scroll;
    width: 100%;
    height: 100%;
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
            }
        }
        .header-part {
            height: 32px;
            .apply-info {
                float: right;
                font-size: 14px;
                width: 230px;
                line-height: 20px;
                color: #606266;
            }
        }
        .el-menu.el-menu--horizontal {
            width: 150px;
            .el-menu-item {
                height: 35px;
                line-height: 35px;
                font-weight: 600;
                font-size: 16px;
                &.is-active {
                    color: #4285F5
                }
            }
        }
    .table-box,
    .edit-table-box {
        margin-bottom: 5px;
        .edit-filter-box {
            display: inline-block;
            width: 60px;
            .el-input__inner {
                padding-right: 24px;
            }
        }
    }
    .el-pagination {
        float: right;
    }
    .list-box {
        padding-right: 10px;
        &.second-box {
            &.margin-box {
                margin-top: 25px;
            }
        }
        &.third-box {
            margin-top: 45px;
            border-bottom: 1px solid #ebeef5;
        }
        .model-table-search {
            margin-top: 10px;
        }
        .file-infos {
            width: 100%;
            padding: 0 10px 0 0;
            .file-list {
                width: 100%;
                padding: 2px 0 2px 4px;
                display: flex;
                // justify-content: space-between;
                .file-name {
                    // flex: 1;
                    &.upload {
                        width: 500px;
                    }
                    padding-right: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
                .view-btn, .delete-btn {
                    width: 200px;
                    display: flex;
                    a {
                        text-decoration: none;
                        color: #4285F5;
                    }
                    .el-button {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .iflow-box {
        .header-operation {
            margin-top: 10px;
        }
        iframe {
            width: 100%;
            height: 100vh;
            border: none;
        }
    }
}
</style>
