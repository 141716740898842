<template>
    <div class="check-dialog">
        <el-dialog
            :visible="true"
            width="500px"
            :title="dialogTitle"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <div class="check-dialog-tips">
                <span v-if="dialogType === 'query'">
                    确认选取{{ queryCheckAll ? '全部' : (rows.length + '条') }}记录进行变更？<br>
                    如需批量更新责任人，请填写选框内容。
                </span>
                <span v-else>
                    将选取{{ detailCheckAll ? '全部' : (rows.length + '条') }}记录的责任人批量修改为：
                </span>
            </div>
            <el-form ref="ruleForm" :model="form" label-width="80px" :rules="rules" label-position="right">
                <el-form-item :label="config.label" :prop="config.prop" label-width="80" label-position="right">
                    <lots-md-input
                            :config="config"
                            @change="getAdvanceValue">
                        </lots-md-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <lots-button size="mini" @click="emit('handleClose', false)">取消</lots-button>
                <lots-button type="primary" size="mini" @click="confirm" :loading="confirmLoading">确认</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import lotsMdInput from '@/components/lots/lotsMdInput';
import { tableConfig } from '../config.js';
export default {
    components: {
        lotsButton,
        lotsMdInput
    },
    props: {
        confirmLoading: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Array,
            default: () => []
        },
        queryCheckAll: {
            type: Boolean,
            default: false
        },
        detailCheckAll: {
            type: Boolean,
            default: false
        },
        dialogTitle: {
            type: String,
            default: ''
        },
        dialogType: {
            type: String,
            default: ''
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const { emit } = setupContext;
        const rules = {
            respOwnerName: [{ required: props.dialogType === 'detail', message: '请选择责任人', trigger: 'change' }]
        };
        const form = reactive({
            respOwnerName: '',
            respOwnerCode: '',
            respOwnerId: ''
        });
        const config = reactive(tableConfig.columns.find(item => item.prop === 'respOwnerName'));
        const getAdvanceValue = (data) => {
            form.respOwnerName = data.respOwnerName;
            form.respOwnerCode = data.respOwnerCode;
            form.respOwnerId = data.respOwnerId;
        };
        const confirm = () => {
            setupContext.refs.ruleForm.validate((flag) => {
                if (flag) {
                    if (form.respOwnerName || form.respOwnerCode || form.respOwnerId) {
                        emit('confirm', form);
                    } else {
                        emit('confirm', false);
                    }
                }
            });
        };
        return {
            emit,
            rules,
            form,
            config,
            confirm,
            getAdvanceValue
        };
    }
};
</script>
<style lang="less">
.check-dialog {
    .el-dialog__body {
        padding: 15px 20px 5px;
    }
    .check-dialog-tips {
        line-height: 24px;
        margin-bottom: 10px;
    }
    .el-input.el-input-group {
        width: 200px;
    }
}
</style>
